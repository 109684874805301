<template>
  <div>
    <!--  这里直接用扩展头部组件了，再包一层就难以处理了  -->
    <FilterHeader
      :show-del-btn="showDelBtn"
      @deleteTableData="deleteTableData"
    />
    <TableHeader
      :title="'部门管理'"
      :icon="'el-icon-collection-tag'"
      @insertCustomer="insertCustomer"
    >
      <div slot="dialog">
        <el-form label-position="right" :model="receivableData">
          <el-form-item
            v-for="item in columnConfig"
            :key="item.prop"
            :label="item.label"
            label-width="110px"
            required
            v-show="item.show !== false"
          >
            <el-input v-model="receivableData[item.prop]" />
          </el-form-item>
          <el-form-item label-width="110px" label="创建人" required>
            <el-select
              style="width: 100%"
              placeholder="请选择"
              v-model="insertStaffID"
            >
              <el-option
                v-for="item in staff_store.staff_info"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </TableHeader>
    <Table
      :column-config="columnConfig"
      :table-data="receivable_store.receivable_info"
      @getDelData="getDelData"
      @editData="editData"
      @updateData="updateData"
    >
      <div slot="dialog">
        <el-form label-position="right" :model="rowData">
          <el-form-item
            v-for="item in columnConfig"
            :key="item.prop"
            :label="item.label"
            label-width="110px"
            required
            v-show="item.show !== false"
          >
            <el-input v-model="rowData[item.prop]" />
          </el-form-item>
          <el-form-item label-width="110px" label="创建人" required>
            <el-select
              style="width: 100%"
              placeholder="请选择"
              v-model="updateStaffID"
            >
              <el-option
                v-for="item in staff_store.staff_info"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </Table>
  </div>
</template>

<script>
import Table from '../common/Table';
import TableHeader from '../common/TableHeader';
import FilterHeader from '../common/FilterHeader';
import {
  DELETE_RECEIVABLE_INFO,
  GET_RECEIVABLE_INFO,
  GET_STAFF_INFO,
  INSERT_RECEIVABLE_INFO,
  UPDATE_RECEIVABLE_INFO,
} from '../../store/type';
import { showMsg } from '../../util/msg';
import { mapState } from 'vuex';
import { timeStamp2String } from '../../util/timeStampConvert';
export default {
  name: 'ReceivableTable',
  components: {
    TableHeader,
    Table,
    FilterHeader,
  },
  data() {
    return {
      columnConfig: [
        { prop: 'createdAt', label: '创建时间' },
        { prop: 'updatedAt', label: '更新时间' },
        { prop: 'type', label: '支付方式' },
        { prop: 'amount', label: '总花费' },
        { prop: 'remark', label: '备注' },
        { prop: 'creator.name', label: '创建人', show: false },
        { prop: 'creator.sex', label: '性别', show: false },
        { prop: 'creator.age', label: '年龄', show: false },
      ],
      showDelBtn: false,
      delData: [],
      receivableData: {},
      rowData: {},
      updateStaffID: undefined,
      insertStaffID: undefined,
    };
  },
  methods: {
    formDataInfo(data, id) {
      return {
        createdAt: timeStamp2String(data.createdAt),
        updatedAt: timeStamp2String(data.updatedAt),
        type: data.type,
        amount: data.amount,
        remark: data.remark,
        creator: id,
        id: data.id,
      };
    },
    deleteTableData() {
      this.$store.dispatch(DELETE_RECEIVABLE_INFO, this.delData);
      showMsg('success', '删除成功！');
    },
    insertCustomer() {
      if (!this.insertStaffID) {
        showMsg('error', '请检查您的选择!', false);
        return;
      }
      this.$store.dispatch(
        INSERT_RECEIVABLE_INFO,
        this.formDataInfo(this.receivableData, this.insertStaffID),
      );
      showMsg('success', '插入成功！');
    },
    updateData() {
      if (!this.updateStaffID) {
        showMsg('error', '请检查您的选择!', false);
        return;
      }
      this.$store.dispatch(
        UPDATE_RECEIVABLE_INFO,
        this.formDataInfo(this.rowData, this.updateStaffID),
      );
      showMsg('success', '更新成功！');
    },
    getDelData(val) {
      this.showDelBtn = val.length > 0;
      this.delData = val;
    },
    editData(data) {
      this.rowData = data;
    },
  },
  computed: {
    ...mapState({
      receivable_store: state => state.receivable_store,
      staff_store: state => state.staff_store,
    }),
  },
  created() {
    this.$store.dispatch(GET_RECEIVABLE_INFO);
    this.$store.dispatch(GET_STAFF_INFO, {
      url: '/employees',
      method: 'get',
    });
  },
};
</script>
