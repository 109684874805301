<template>
  <div style="padding-top: 5px">
    <ReceivableTable />
    <ReceivablePagination />
  </div>
</template>

<script>
import ReceivableTable from './ReceivableTable';
import ReceivablePagination from './ReceivablePagination';
export default {
  name: 'ReceivableContainer',
  components: { ReceivablePagination, ReceivableTable },
};
</script>
